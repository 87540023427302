ajaxTestimonialsOnFormSubmit();
testimonialsMonthPagination();

function ajaxTestimonialsOnFormSubmit() {
	const $forms = $('.js-form-filter-testimonials > form');

	$forms.on('submit', function(event) {
		console.log('submit')
		event.preventDefault();

		const $this = $(this);
		const $ajaxContainer = $this.closest('.list-team');
		const ajaxURL = $this.attr('action');
		const year    = $this.find('.js-block-select--year .block__head').attr('data-value');

		const data = {
			action: 'crb_ajax_get_testimonials',
			year: year,
		}

		ajaxTestimonials(data, ajaxURL, $ajaxContainer);
	});
}

function ajaxTestimonials(data, ajaxURL, $ajaxContainer) {
	$.ajax({
		url: ajaxURL,
		method: 'POST',
		dataType: 'json',
		data: data,
		beforeSend: function() {
			$ajaxContainer.addClass('is-loading');
			$ajaxContainer.find('.list__body .list__row .list__col').remove();
			$ajaxContainer.find('.list__body').append('<i class="ico-loading"></i>');

		},
		success: function(response) {
			if (response === 'error') {
				alert( 'Something went wrong. Please reload the page and try again.' );

				return;
			}

			const $prevBtn = $ajaxContainer.find('.js-testimonial-year-pagination.btn-pagination--prev');
			const $nextBtn = $ajaxContainer.find('.js-testimonial-year-pagination.btn-pagination--next');


			const $listOfMonths = $ajaxContainer.find('.js-block-select--year .block__body ul li a');

			const $blockHead = $ajaxContainer.find('.js-block-select--year .block__head');

			$blockHead.attr('data-value', data.year).text(`- Promo ${data.year}`);

			const prevYear = (parseInt( $blockHead.attr('data-value') ) - 1).toString();
			const nextYear = (parseInt( $blockHead.attr('data-value') ) + 1).toString();

			$prevBtn.addClass('is-hidden');

			$listOfMonths.each((index, elem) => {

				if ($(elem).attr('data-value') === prevYear) {
					$prevBtn.removeClass('is-hidden').attr('data-year', prevYear).find('span').text(`Promo ${prevYear}`);

					return false;
				}
			});

			$nextBtn.addClass('is-hidden');

			$listOfMonths.each((index, elem) => {

				if ($(elem).attr('data-value') === nextYear) {
					$nextBtn.removeClass('is-hidden').attr('data-year', nextYear).find('span').text(`Promo ${nextYear}`);

					return false;
				}
			});

			$ajaxContainer.removeClass('is-loading')
			$ajaxContainer.find('.ico-loading').remove();
			$ajaxContainer.find('.list__body .list__row').html(response.testimonials);
		},
	});
}

function testimonialsMonthPagination() {
	$(document).on('click', '.js-testimonial-year-pagination', function(event) {
		event.preventDefault();

		const $this = $(this);
		const $ajaxContainer = $this.closest('.list-team')

		const $form = $ajaxContainer.find('form');
		let year = $this.attr('data-year');

		const ajaxURL = $this.data('ajax-url');

		const data = {
			action: 'crb_ajax_get_testimonials',
			year: year
		}

		ajaxTestimonials(data, ajaxURL, $ajaxContainer);
	});
}

import { $document } from '../utils/globals';

$('.js-gallery-popup-trigger').on('click', function() {
	const $this = $(this);
	const imageURL = $this.attr('data-image-url');
	const currentImageNum = $this.attr('data-image-num')

	const $gallery = $this.closest('.js-gallery');
	const imageCount = $gallery.attr('data-image-count');

	let $galleryPopup = $('.js-gallery-popup');

	if (! $galleryPopup.length) {
		$('.wrapper .wrapper__inner').append(`
			<div class="popup-gallery js-gallery-popup">
				<div class="popup__inner">
					<div class="popup__head">
						<div class="popup__counter">
							<span class="popup__counter-current">1</span> / <span class="popup__counter-max">20</span>
						</div><!-- /.popup__counter -->

						<a href="#" class="popup__btn-close btn-x js-close-gallery-popup"></a>
					</div><!-- /.popup__head -->

					<div class="popup__image">
						<div class="shell">
							<i class="ico-loading"></i>
						</div><!-- /.shell -->
					</div><!-- /.popup__image -->

					<div class="popup__actions">
						<a href="#" class="popup__prev js-popup-gallery-switch js-popup-gallery-switch-prev">
							<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
						</a>

						<a href="#" class="popup__next js-popup-gallery-switch js-popup-gallery-switch-next">
							<i class="fa fa-long-arrow-right" aria-hidden="true"></i>
						</a>
					</div><!-- /.popup__actions -->
				</div><!-- /.popup__inner -->
			</div><!-- /.popup-gallery -->
		`);
	}

	$galleryPopup = $('.js-gallery-popup');

	$galleryPopup.find('.popup__counter-current').text(currentImageNum);
	$galleryPopup.find('.popup__counter-max').text(imageCount);

	const $images = $gallery.find('.gallery__image');

	$images.each((index, elem) => {
		const $elem = $(elem);
		const url = $elem.attr('data-image-url');

		$galleryPopup.find('.popup__image .shell').append(`<img data-num="${index + 1}" src="${url}"/>`);
	});


	$galleryPopup.addClass('is-active');

	setTimeout(function() {
		$galleryPopup.find('.popup__image img').eq(parseInt(currentImageNum) - 1).addClass('current');
		$galleryPopup.find('.ico-loading').remove();
	}, 1000);
});

$document.on('click', function(event) {
	const $target = $(event.target);

	if (! $target.is('.js-close-gallery-popup') && ! $target.is('.popup-gallery .popup__image')) {
		return;
	}

	event.preventDefault();

	const $galleryPopup = $('.js-gallery-popup');

	$galleryPopup.removeClass('is-active').find('img.current').removeClass('current');

	setTimeout(function() {
		$galleryPopup.find('img').remove();

		$galleryPopup.find('.popup__image .shell').append('<i class="ico-loading"></i>');
	}, 400);
});

$document.on('click', '.js-popup-gallery-switch', function(event) {
	const $this = $(this);
	const direction = $this.is('.js-popup-gallery-switch-prev') ? 'back' : 'forward';
	const $activeImage = $this.closest('.js-gallery-popup').find('img.current');
	const $counter = $this.closest('.js-gallery-popup').find('.popup__counter-current');

	$activeImage.removeClass('current');

	if (direction === 'back') {
		if ($activeImage.is(':first-child')) {
			const $newImage = $activeImage.siblings(':last-child');

			$newImage.addClass('current');

			$counter.text($newImage.attr('data-num'));
		} else {
			const $newImage = $activeImage.prev();

			$newImage.addClass('current');

			$counter.text($newImage.attr('data-num'));
		}
	} else {
		if ($activeImage.is(':last-child')) {
			const $newImage = $activeImage.siblings(':first-child');

			$newImage.addClass('current');

			$counter.text($newImage.attr('data-num'));
		} else {
			const $newImage = $activeImage.next();

			$newImage.addClass('current');

			$counter.text($newImage.attr('data-num'));
		}
	}
});

document.querySelectorAll('.youtube-cover-img').forEach(cover => {
	cover.addEventListener('click', function () {
			let wrapper = this.closest('.youtube-content-wrapper');
			let iframe = wrapper.querySelector('.youtube-iframe');
			let playIcon = wrapper.querySelector('.play-icon');

			if (iframe) {
					iframe.style.opacity = 0;
					iframe.style.display = 'block';

					setTimeout(() => {
							iframe.style.transition = 'opacity 0.5s ease-in-out';
							iframe.style.opacity = 1;
					}, 10);
			}

			if (playIcon) {
					playIcon.style.transition = 'opacity 0.5s ease-in-out';
					playIcon.style.opacity = 0;
					setTimeout(() => {
							playIcon.style.display = 'none';
					}, 500);
			}
	});
});

const filterBtn = document.querySelector(".filter-button");
const formationsFilters = document.querySelector(".formations-filter");
const closeBtn = document.querySelector(".close-filters");
const overlay = document.querySelector(".overlay");

if(filterBtn) {
	filterBtn.addEventListener("click", function(e) {
		e.preventDefault();
		formationsFilters.classList.add("active");
		overlay.classList.add("active");
	});
}

if(closeBtn) {
	closeBtn.addEventListener("click", function(e) {
		e.preventDefault();
		formationsFilters.classList.remove("active");
		overlay.classList.remove("active");
	});
}

if(overlay) {
	overlay.addEventListener("click", function() {
		formationsFilters.classList.remove("active");
		overlay.classList.remove("active");
	});
}

import { tns } from 'tiny-slider/src/tiny-slider';
import className from '../utils/constant-names';

const _element   = '.js-slider-gallery';
const $container = $(_element);

if ( $container.length ) {

	const $slider = [];

	$container.find( '.js__slides' ).each( function( i ) {
		$slider[i] = tns({
			container: this,
			nav: false,
			items: 1,
			speed: 400,
			autoplay: true,
			autoplayButtonOutput: false
		});
	});
}

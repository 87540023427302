import { $window } from '../utils/globals';
import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';
import InfiniteScroll from 'infinite-scroll';

const _element   = '.js-infinite-scroll';
const $container = $(_element);

if ( $container.length ) {

	const _hasNextPage = $container.find( '.js__next-page' ).length;

	$container.find( '.js__row' ).each( function() {
		let msnry = null;
		let $this = this;

		msnry = new Masonry(this, {
			itemSelector: 'none',
			columnWidth: '.js__sizer',
			gutter: 0,
			percentPosition: true,
			stagger: 30
		});

		imagesLoaded( this, function() {
			$this.classList.remove( 'images-uploaded' );

			msnry.options.itemSelector = '.js__col';

			let items = $this.querySelectorAll( '.js__col' );

			msnry.appended( items );
		});

		if ( _hasNextPage ) {
			InfiniteScroll.imagesLoaded = imagesLoaded;

			let infScroll = new InfiniteScroll( this, {
				path: '.js__next-page',
				append: '.js__row',
				outlayer: msnry,
				status: '.list__status',
			});
		}
	});
}

import Swiper from 'swiper/swiper-bundle';

const swiper = new Swiper('.event-slider', {
  grabCursor: true,

  navigation: {
    nextEl: '.btn-arrow--next',
    prevEl: '.btn-arrow--prev',
  },
});

const actus_slider = new Swiper('.actus-slider', {
  grabCursor: true,
	loop: true,
	slidesPerView: 1.1,
	spaceBetween: 17,

  navigation: {
    nextEl: '.btn-arrow--next',
    prevEl: '.btn-arrow--prev',
  },

	breakpoints: {
		576: {
			slidesPerView: 2,
		},
		992: {
			slidesPerView: 3,
		},
		1220: {
			slidesPerView: 2.8,
		},
	},
});

const thumbs_slider = new Swiper('.thumbs-swiper', {
	slidesPerView: 4,
	freeMode: true,
	watchSlidesProgress: true,
	clickable: true,
});

const stats_slider = new Swiper('.stats-slider', {
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
	grabCursor: true,
	effect: "fade",
	spaceBetween: 15,
	loop: true,
	thumbs: {
		swiper: thumbs_slider,
	},
	on: {
    autoplayTimeLeft(swiper, timeLeft, percentage) {
      const activeSlide = document.querySelector('.thumbs-swiper .swiper-slide-thumb-active');
      activeSlide.style.setProperty('--autoplay-duration', `${swiper.params.autoplay.delay}ms`);
    },
    slideChangeTransitionStart() {
      const slides = document.querySelectorAll('.thumbs-swiper .swiper-slide');
      slides.forEach(slide => {
        slide.style.removeProperty('animation');
      });
    },
  },
});

const partners_slider = new Swiper('.partners-slider', {
  grabCursor: true,
	loop: true,
	centeredSlides: true,
	slidesPerView: 1.5,
	spaceBetween: 32,
	autoplay: {
		delay: 2000,
		disableOnInteraction: false,
	},

  navigation: {
    nextEl: '.btn-arrow--next',
    prevEl: '.btn-arrow--prev',
  },

	breakpoints: {
		575: {
			slidesPerView: 2.5,
		},
		768: {
			slidesPerView: 3.5,
		},
		1024: {
			slidesPerView: 5.5,
		},
	},
});

let sector_slider;

function initSwiper() {
	if (window.innerWidth <= 1416 && !sector_slider) {
		sector_slider = new Swiper('.sector-slider', {
			grabCursor: true,
			slidesPerView: 1.2,
			spaceBetween: 13,

			navigation: {
				nextEl: '.btn-arrow--next',
				prevEl: '.btn-arrow--prev',
			},

			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
		});
	} else if (window.innerWidth > 1416 && sector_slider) {
    sector_slider.destroy(true, true);
    sector_slider = null;
  }
}

initSwiper();

window.addEventListener('resize', initSwiper);

import { tns } from 'tiny-slider/src/tiny-slider';
import className from '../utils/constant-names';

const _element   = '.js-slider-boxes';
const $container = $(_element);

if ( $container.length ) {

	const $slider = [];

	$container.each( function( i ) {
		const $this = $(this);

		$this.find( '.js__slides' ).each( function() {
			const $slides = $(this);

			$slider[i] = tns({
				container: this,
				nav: false,
				items: 4,
				gutter: 30,
				speed: 400,
				// autoplay: true,
				// autoplayButtonOutput: false,
				// autoplayResetOnVisibility: true,
				responsive: {
					0: {
						items: 1,
						gutter: 0
					},
					768: {
						items: 3,
						gutter: 30
					},
					1024: {
						items: 4,
						gutter: 30
					}
				}
			});

			$slider[i].events.on( 'transitionStart', function() {
				$slides.addClass( className.IsAnimate );
			});

			$slider[i].events.on( 'transitionEnd', function() {
				$slides.removeClass( className.IsAnimate );
			});
		});

		$this.on( 'click', '.tns-controls > button', function() {
			$slider[i].play();
		});
	});
}

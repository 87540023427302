import AOS from 'aos';
import { $window, $body } from '../utils/globals';
import className from '../utils/constant-names';
import scrollToSection from '../utils/scroll-to-section';

const _element   = '.js-block-select--filter';
const $container = $(_element);

if ( $container.length ) {

	let _offset = 0;
	let _timer  = null;

	setOffset();

	$window.on( 'load resize orientationchange', function() {
		setOffset();
	});

	$window.on( 'scroll', function() {
		$body.toggleClass( className.ShowFixedFilter, $window.scrollTop() >= _offset );
	});

	$container.find( 'a' ).on( 'click', function( event ) {
		event.preventDefault();

		$('.featured-formation-courtes').hide();

		const $this = $(this);
		const $linkContainer = $this.closest('.js-block-select--filter');
		const $parent = $this.parent();

		$parent.toggleClass( className.Active );

		const attendaceFiltering = $linkContainer.is('.js-block-select--filter-attendance') ? true : false;

		setFilter(attendaceFiltering);

		if ( $parent.hasClass( className.Active ) ) {
			const _href  = $this.attr( 'href' );
			const _split = _href.split( '#' );

			$( '.js-section-filter' ).each((index, elem) => {
				const $elem = $(elem);

				if (! $linkContainer.is('.js-block-select--no-scroll')) {
					if ($elem.attr('id') === _split[1]) {
						scrollToSection( $elem );
					}
				}
			});

		}
	});

	function setFilter( attendaceFilter = false ) {
		clearTimeout( _timer );

		if (attendaceFilter) {
			$('.article-box').parent().removeClass('hidden');
			$('.js-section-filter').removeClass('no-active-articles');

			if ($('.js-block-select--filter-attendance').find( '.' + className.Active + ' > a' ).length === $('.js-block-select--filter-attendance').find( '.block__body ul a' ).length) {
				return;
			}
		} else {
			$('.js-section-filter').removeClass('active');
		}

		if (attendaceFilter) {
			$('.js-block-select--filter-attendance').find( '.' + className.Active + ' > a' ).each( function() {
				const $this  = $(this);
				const $linkContainer  = $this.closest('.js-block-select--filter');
				const _href  = $this.attr( 'href' );
				const _split = _href.split( '#' );

				$( '.js-section-filter' ).each((index, elem) => {
					const $elem = $(elem);

					$elem.find('.article-box').each((index, elem) => {
						const $elem = $(elem);

						console.log($elem.attr('data-attendance') !== _split[1])

						if ($elem.attr('data-attendance') !== _split[1]) {
							$elem.parent().addClass('hidden');
						}
					});

					if (! $elem.find('.grid .grid__col:not(.hidden)').length) {
						$elem.addClass('no-active-articles');
					}
				});
			});
		} else {
			$('.js-block-select--filter:not(.js-block-select--filter-attendance)').find( '.' + className.Active + ' > a' ).each( function() {
				const $this  = $(this);
				const $linkContainer  = $this.closest('.js-block-select--filter');
				const _href  = $this.attr( 'href' );
				const _split = _href.split( '#' );

				$( '.js-section-filter' ).each((index, elem) => {
					const $elem = $(elem);

					if ($elem.attr('id') === _split[1]) {
						$elem.addClass( className.Active );

						if ($elem.find('.grid .grid__col:not(.hidden)').length) {
							$elem.removeClass('no-active-articles');
						}
					}
				});
			});
		}



		_timer = setTimeout(function() {
			AOS.refresh();
		}, 400);
	}

	function setOffset() {
		_offset = parseInt( $container.parents( 'section' ).offset().top, 10 );
	}
}

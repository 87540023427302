const _element   = '.js-block-select--form';
const $container = $(_element);

if ( $container.length ) {
	addBlockSelectFormEventListeners();

	function addBlockSelectFormEventListeners() {
		$container.find('.block__body ul').on('click', 'li a', function(event) {
			event.preventDefault();

			switchBlockSelectFormSelectedItem($(this));
		});
	}

	function switchBlockSelectFormSelectedItem($newItem) {
		const text = $newItem.text();
		const value = $newItem.attr('data-value');

		$newItem.closest('.block__body').prev().text(text).attr('data-value', value);
	}
}

import 'magnific-popup';

const _element   = '.js-popup-video';
const $container = $(_element);

if ( $container.length ) {

	$container.magnificPopup({
		type: 'iframe',
		removalDelay: 400,
		mainClass: 'mfp-fade',
		iframe: {
			markup: '<div class="mfp-iframe-scaler">'+
						'<div class="mfp-close"></div>'+
						'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
					'</div>',
			patterns: {
				youtube: {
					index: 'youtube.com/',
					id: 'v=',
					src: 'https://www.youtube.com/embed/%id%?autoplay=1'
				},
				vimeo: {
					index: 'vimeo.com/',
					id: '/',
					src: 'https://player.vimeo.com/video/%id%?autoplay=1'
				},
				gmaps: {
					index: 'https://maps.google.',
					src: '%id%&output=embed'
				}
			},
			srcAction: 'iframe_src'
		}
	});
}

import SimpleScrollbar from 'simple-scrollbar';
import className from '../utils/constant-names';

const _element   = '.js-block-select';
const $container = $(_element);

if ( $container.length ) {

	$container.each( function() {
		const _customScrollBar = this.querySelector( '.js__body' );

		SimpleScrollbar.initEl( _customScrollBar );
	});
}

import { $window } from '../utils/globals';

function addMinHeightToScrollFilter() {
	let maxHeight = 0;
	const $filter = $('.js-formation-filter');

	if (! $filter.length) {
		return;
	}

	const introHeight = $('.section-intro').outerHeight();

	$filter.find('.block-select').each((index, elem) => {
		const $elem = $(elem);
		const height = $elem.find('.block__body').outerHeight();

		if (height > maxHeight) {
			maxHeight = height;
		}
	});

	$($('.main')[0]).css({
		'minHeight': `${maxHeight + introHeight}px`
	});
}

$window.on('load', function() {
	addMinHeightToScrollFilter();
});

import { $htmlBody } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-btn-go-top';
const $container = $(_element);

if ( $container.length ) {

	$container.on( 'click', function( event ) {
		event.preventDefault();

		$htmlBody.delay(100).stop(true, true).animate({
			scrollTop: 0
		}, 1000);
	});
}

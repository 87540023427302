export default {
	Hover:           'hover',
	Active:          'active',
	Open:            'open',
	IsTouch:         'is-touch',
	IsIos:           'is-ios',
	IsIE:            'is-ie',
	IsEdge:          'is-edge',
	IsAnimate:       'is-animate',
	ShowFixedHeader: 'show-fixed-header',
	ShowNavMain:     'show-nav-main',
	ShowNavLarge:    'show-nav-large',
	ShowFixedFilter: 'show-fixed-filter',
	PageLoaded:      'page-loaded',
	BeforeBreak:     'before-break',
	AfterBreak:      'after-break',
};

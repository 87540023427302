import { $body } from '../utils/globals';

$('.js-search-popup').on('click', function(event) {
	event.preventDefault();

	$body.addClass('show-search-popup');
});

$('.js-popup-search').on('click', function(event) {
	const $target = $(event.target);

	if (! $target.is('.js-close-popup-search, .popup-search .popup__inner')) {
		return;
	}

	event.preventDefault();

	$body.removeClass('show-search-popup');
});

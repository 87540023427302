import className from '../utils/constant-names';

/**
 * Init Block Map
 *
 * @return {void}
 */

const initBlockMap = (_element = '.js-section--map') => {
	const $container = $(_element);

	if( !$container.length ) {
		return false;
	}

	const _style = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#f7f7f7"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];

	let _activeMarker = 0;
	let marker    = [];
	let map       = null;
	let myOptions = null;
	let bounds    = new google.maps.LatLngBounds();

	initMap();

	function initMap() {
		$container.each( function( i ) {
			const $this     = $(this);
			const $map      = $this.find('.js__map');
			const _zoom     = $map.data('zoom');
			const _marker   = $map.data('marker');
			const _markerA  = $map.data('marker-active');
			const _markerW  = $map.data('marker-width');
			const _markerH  = $map.data('marker-height');
			const _markerIW = $map.data('marker-inactive-width');
			const _markerIH = $map.data('marker-inactive-height');
			const _id       = 'js-section-map-id' + i;

			$map.attr('id', _id);

			let markerIcon = {
				url: _marker,
				scaledSize: new google.maps.Size(_markerIW, _markerIH),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(-5,28),
				labelOrigin:  new google.maps.Point(parseInt(_markerIW / 2, 10),parseInt(_markerIH / 2, 10)),
			};

			let markerIconActive = {
				url: _markerA,
				scaledSize: new google.maps.Size(_markerW, _markerH),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(32,65),
				labelOrigin:  new google.maps.Point(parseInt(_markerW / 2, 10),parseInt(_markerH / 2, 10)),
			};

			myOptions = {
				zoom: _zoom,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				sensor: 'true',
				panControl: false,
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				overviewMapControl: false,
				fullscreenControl: false,
				styles: _style
			};

			map = new google.maps.Map(document.getElementById(_id), myOptions);

			$this.find( '.js__location' ).each(function( i ) {
				const $location = $(this);
				const _address  = $location.data('location').split(',');
				const _icon     = ( i == 0 ) ? markerIconActive : markerIcon;

				marker[i] = new google.maps.Marker({
					map: map,
					animation: google.maps.Animation.DROP,
			  		position: new google.maps.LatLng(_address[0], _address[1]),
					icon: _icon
				});

				marker[i].addListener('click', function() {
					setActiveMarker( i, markerIconActive, markerIcon );
				});

				bounds.extend(marker[i].getPosition());

				map.fitBounds(bounds);
			});

			$this.find( '.js__click-location:not(:last-child)' ).on( 'click', function( event ) {
				event.preventDefault();

				const $this = $(this);
				const _index = $this.index();

				setActiveMarker( _index, markerIconActive, markerIcon )
			});

			$this.find( '.js__zoom-in' ).on( 'click', function( event ) {
				event.preventDefault();

				map.setZoom( map.getZoom() + 1 );

			});

			$this.find( '.js__zoom-out' ).on( 'click', function( event ) {
				event.preventDefault();

				map.setZoom( map.getZoom() - 1 );
			});
		});
	}

	function setActiveMarker( _index, iconHover, iconDefault ) {
		_activeMarker = _index;

		$( '.js__click-location:not(:last-child)' ).eq( _index ).addClass( className.Active ).siblings().removeClass( className.Active );

		$.each(marker, function( i ) {
			if ( i == _index ) {
				marker[i].setIcon(iconHover);
				marker[i].setZIndex(100);
			} else {
				marker[i].setIcon(iconDefault);
				marker[i].setZIndex(90);
			}
		});

		setActiveAccordionList();
	}	
};

export default initBlockMap;

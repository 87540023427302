ajaxEventsOnFormSubmit();
eventsMonthPagination();

function ajaxEventsOnFormSubmit() {
	const $forms = $('.js-form-filter-events > form');

	$forms.on('submit', function(event) {
		event.preventDefault();

		const $this = $(this);
		const $ajaxContainer = $this.closest('.list-posts');
		const ajaxURL = $this.attr('action');
		const theme = $this.find('.js-block-select--theme .block__head').attr('data-value');
		const month = $this.find('.js-block-select--date .block__head').attr('data-value');

		const data = {
			action: 'crb_ajax_get_events',
			theme: theme,
			month: month
		}

		ajaxEvents(data, ajaxURL, $ajaxContainer);
	});
}

function ajaxEvents(data, ajaxURL, $ajaxContainer) {
	$.ajax({
		url: ajaxURL,
		method: 'POST',
		dataType: 'json',
		data: data,
		beforeSend: function() {
			$ajaxContainer.addClass('is-loading');
			$ajaxContainer.find('.list__body .list__row .list__col').remove();
			$ajaxContainer.find('.list__body').append('<i class="ico-loading"></i>');

		},
		success: function(response) {
			if (response === 'error') {
				alert( 'Something went wrong. Please reload the page and try again.' );

				return;
			}

			const $eventDates = $('.events-date');
			const $prevBtn = $ajaxContainer.find('.js-event-month-pagination.btn-pagination--prev');
			const $nextBtn = $ajaxContainer.find('.js-event-month-pagination.btn-pagination--next');

			const $listOfMonths = $ajaxContainer.find('.js-block-select--date .block__body ul');

			$eventDates.text(response.current_month);
			$prevBtn.attr('data-month', response.prev_month_string).find('span').text(response.prev_month);
			$nextBtn.attr('data-month', response.next_month_string).find('span').text(response.next_month);

			let listOfMonthsContainsPrevMonth = false;
			let listOfMonthsContainsNextMonth = false;

			$listOfMonths.find('a').each((index, elem) => {
				if ($(elem).attr('data-value') === data.month) {
					listOfMonthsContainsPrevMonth = true;
				}

				if ($(elem).attr('data-value') === data.month) {
					listOfMonthsContainsNextMonth = true;
				}
			});

			if (! listOfMonthsContainsPrevMonth) {
				$listOfMonths
					.prepend(`<li><a data-value="${data.month}" href="#">- Du 1er au 31 ${data.month_string}</a></li>`);
			}

			if (! listOfMonthsContainsNextMonth) {
				$listOfMonths
					.append(`<li><a data-value="${data.month}" href="#">- Du 1er au 31 ${data.month_string}</a></li>`);

			}

			if ('month_string' in data) {
				$listOfMonths
					.closest('.js-block-select--date')
						.find('.block__head')
						.attr('data-value', data.month)
						.text(`- Du 1er au 31 ${data.month_string}`);
			}

			$ajaxContainer.removeClass('is-loading')
			$ajaxContainer.find('.ico-loading').remove();

			const $listRows = $ajaxContainer.find('.list__body .list__row');
			$listRows.html(response.events);
			$listRows.addClass('grid-corrector');
		},
	});
}

function eventsMonthPagination() {
	$(document).on('click', '.see-next-month', function(e) {
		e.preventDefault();
		$('.btn-pagination--next').trigger('click');
	});

	$(document).on('click', '.js-event-month-pagination', function(event) {
		event.preventDefault();

		const $this = $(this);
		const $posts = $this.closest('.list-posts')

		const $form = $posts.find('form');
		let theme = $posts.find('[data-type="theme"]').attr('data-value');
		let date = $this.attr('data-month');

		const ajaxURL = $this.data('ajax-url');

		const data = {
			action: 'crb_ajax_get_events',
			theme: theme,
			month: date,
			month_string: $this.text()
		}

		ajaxEvents(data, ajaxURL, $posts);

		$([document.documentElement, document.body]).animate({
			scrollTop: $("#section--posts").offset().top - 100
		}, 300);
	});
}

window.addEventListener('load', () => {
  let shareData = {
    title: document.querySelector('h1').innerText,
    url: '',
  };

  const shareButtons = document.querySelectorAll('.share-mobile');

  shareButtons.forEach(function(btn) {
    btn.addEventListener('click', () => {
			shareData.url = btn.dataset.link;
      navigator.share(shareData).then(() => {});
    });
  });
});


import { tns } from 'tiny-slider/src/tiny-slider';
import SimpleScrollbar from 'simple-scrollbar';
import className from '../utils/constant-names';

const _element   = '.js-block-timeline';
const $container = $(_element);

if ( $container.length ) {

	let $slider = [];

	$container.each( function( i ) {
		const $this   = $(this);

		$this.find( '.js__slides' ).each( function( j ) {
			$slider[i] = tns({
				container: this,
				nav: false,
				items: 1,
				speed: 400,
				gutter: 25,
				autoWidth: true,
				loop: false,
				responsive: {
					0: {
						gutter: 20,
					},
					768: {
						gutter: 25,
					}
				}
			});
		});

		$this.find( '.js__slides' ).on( 'click', 'a', function( event ) {
			event.preventDefault();

			const $link     = $(this);
			const $siblings = $link.parent().siblings().find( 'a' );
			const _index    = parseInt($link.data( 'index' ), 10);

			$link.addClass( className.Active );
			$siblings.removeClass( className.Active );

			$this.find( '.js__body > *' ).eq( _index ).addClass( className.Active ).siblings().removeClass( className.Active );

			$slider[i].goTo( _index );
		});

		$this.find( '.js__scroll' ).each( function() {
			SimpleScrollbar.initEl( this );
		});

	});
}

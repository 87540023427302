import AOS from 'aos';
import { $window } from '../utils/globals';

const _element   = '[data-aos]';
const $container = $(_element);

if ( $container.length ) {

	const _settings = {
		duration: 400,
		once: true
	}

	$window.on( 'load', function() {
		AOS.init( _settings );
	});
}

/*
 * Polyfills
 */
import './polyfills/object-fit';
import './polyfills/add-body-class-on-page-load';
import './polyfills/add-fixed-header-class-on-scroll';
import './polyfills/add-body-class-if-is-touch';
import './polyfills/add-body-class-if-is-ios';
import './polyfills/add-body-class-if-is-ie';
import './polyfills/nav-is-hover-on-touch';
import './polyfills/btn-menu-on-click';
import './polyfills/init-gravity-reset';
import './polyfills/init-header-nav';
import './polyfills/init-btn-go-top';
import './polyfills/init-slider-boxes';
import './polyfills/init-slider-gallery';
import './polyfills/init-aos-animations';
import './polyfills/init-block-select';
import './polyfills/init-block-select--filter';
import './polyfills/init-block-select--form';
import './polyfills/init-block-heading';
import './polyfills/init-block-timeline';
import './polyfills/init-scroll-to-section';
import './polyfills/init-infinite-scroll';
import './polyfills/init-magnific-popup';
import './polyfills/copy';
import './polyfills/share';

/*
 * Modules
 */

import initAccordions from './modules/init-accordions';
import InitCountUpScrollAnimation from './modules/init-count-up-scroll-animation';
import initBlockMap from './modules/init-block-map';
import initSimpleSlider from './modules/init-simple-slider';
import './modules/ajax-events';
import './modules/ajax-testimonials';
import './modules/disable-autocomplete-on-date-fields';
import './modules/add-minimum-height-to-scroll-filter';
import './modules/formation-filter';
import './modules/gallery-masonry';
import './modules/search-form-toggle.js';
import './modules/search-popup';
import './modules/gallery-popup';

initAccordions();
InitCountUpScrollAnimation();
initBlockMap();
initSimpleSlider();

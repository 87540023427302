const _element   = '.gform_wrapper';
const $container = $( _element );

if ( $container.length ) {

	const _files = [
		'gforms_reset_css-css',
		'gforms_formsmain_css-css',
		'gforms_ready_class_css-css',
		'gforms_browsers_css-css',
		'gforms_stripe_frontend-css',
	];

	$.each(_files, function(index, value) {

		const $id = $('#' + value);

		if ($id.length) {
			$id.remove();
		}
	});

	$container.on( 'change', 'input[type="file"]', function() {
		const $this       = $(this)
		const inputVal    = $this.val();
		const inputValArr = inputVal.split("\\");
		const fileName    = inputValArr[ inputValArr.length - 1 ];
		const $parents    = $this.parents( '.ginput_container' );

		$parents.find( '.ginput_preview' ).remove();
		$parents.find( '.ginput_preview-on-change' ).remove();
		$parents.append( '<div class="ginput_preview-on-change">' + fileName + '</div>');
	});
}

import { $window } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-block-heading';
const $container = $(_element);

if ( $container.length ) {
	$container.find( 'strong' ).each( function() {
		const $this = $(this);
		let   _text = $this.html();

		$this.html( _text.replace( ' ', '&nbsp;' ) );
	});

	$container.find( '> * ' ).each( function() {
		const $this    = $(this);
		let   _arrHtml = $this.html().split(' ');
		let   _html    = '';

		_arrHtml = _arrHtml.filter(function(e){ return e === 0 || e });

		$.each(_arrHtml, function( _index, _value ) {
			_html += '<span><i>' + _value + '</i></span>';
		});

		$this.html( _html );
	});

	$container.find( 'strong' ).each( function() {
		const $this    = $(this);
		const _text    = $this.html();
		const _arrText = _text.split( '&nbsp;' );
		let   _html    = '';

		if ( _arrText.length >= 2 ) {
			$.each( _arrText, function(_index, _value) {
				if ( _index == 0 ) {
					$this.text( _value );
				} else {
					_html += '<span><i><strong>' + _value + '</strong></i></span>';
				}
			});

			$this.parents( 'span' ).after( _html );
		}
	});

	$window.on( 'load resize orientationchange', function() {

		$container.each( function() {
			let _lastOffset = 0;

			$(this).find( 'span' ).each( function( i ) {
				const $this = $(this);
				const _offset = $this.offset().top;

				$this.removeClass( className.AfterBreak );
				$this.removeClass( className.BeforeBreak );

				if ( _offset > _lastOffset && i != 0 ) {
					$this.addClass( className.AfterBreak );
					$this.prev().addClass( className.BeforeBreak );
				}

				_lastOffset = $(this).offset().top;
			});
		});
	});
}

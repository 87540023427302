import { $document } from '../utils/globals';

$('.js-toggle-search-form').on('click', function(event) {
	event.preventDefault();

	$(this).closest('.form-search').toggleClass('is-active');
});

$document.on('click', function(event) {
	const $target = $(event.target);

	if (! $target.closest('.form-search').length) {
		$('.form-search').removeClass('is-active');
	}
});

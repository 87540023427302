import AOS from 'aos';
import className from '../utils/constant-names';
import { $window, $body } from '../utils/globals';

const $filter = $('.js-formation-filter');

if ($filter.length) {
	let timer = false;
	let _offset = 0;

	setOffset();

	$window.on( 'load resize orientationchange', function() {
		setOffset();
	});

	function setOffset() {
		_offset = parseInt( $filter.parents( 'section' ).offset().top + 200, 10 );
	}

	$window.on( 'scroll', function() {
		$body.toggleClass( className.ShowFixedFilter, $window.scrollTop() >= _offset );
	});

	$filter.find('.block-select a').on('click', function(event) {
		if (timer) {
			clearTimeout(timer);
		}

		event.preventDefault();

		const $this = $(this);

		$this.parent().toggleClass('active');

		const $block = $this.closest('.block-select');
		const $input = $block.find('input');
		const $active = $block.find('.block__body ul li.active');

		$input.val('');

		$active.each((index, elem) => {
			const $elem = $(elem);
			const currentValue = $input.val();
			const newValue = $elem.find('a').attr('data-value');
			const separator = index !== 0 ? '|' : '';

			$input.val(currentValue + separator + newValue);
		});

		const categories = $('#formation-filter-category').val();
		const attendance = $('#formation-filter-attendance').val();
		const eligibilityCPF = $('#formation-filter-eligibility-cpf').val();

		filterFormations(categories, attendance, eligibilityCPF);

		timer = setTimeout(function() {
			AOS.refresh();
		}, 400);
	});

	$filter.find('.reset-btn-wrapper a').on('click', function(event) {
		event.preventDefault();

		filterFormations('', '', '');
		$('.js__body li').removeClass('active');

		timer = setTimeout(function() {
			AOS.refresh();
		}, 400);
	});

	function filterFormations(categoriesString, attendanceString, eligibilityCPFString) {
		$('.featured-formation-courtes').hide();

		const $sections       = $('.js-section-filter');
		const $campusSections = $('.js-section-filter-campus');
		const $articles       = $('.article-box');

		let categories = [];

		if (categoriesString.length) {
			$filter.addClass('is-filtering');

			if ($filter.is('.is-filtering')) {
				$sections.removeClass('active');
			}

			categories = categoriesString.split('|');

			categories.forEach((elem, index) => {
				$(`.js-section-filter[data-id="${elem.toString()}"]`).addClass('active');
			});

			const newBackground = $($('.js-section-filter.active')[0]).attr('data-bg');

			$('.section-scroll-to').attr('data-background', newBackground);
		} else {
			if ($filter.is('.is-filtering')) {
				$sections.removeClass('active');
			}
		}

		let attendance = [];
		let eligibilityCPF = [];

		if (attendanceString.length) {
			attendance = attendanceString.split('|');
		}

		if (eligibilityCPFString.length) {
			eligibilityCPF = eligibilityCPFString.split('|');
		}

		if ( attendance.length ) {
			$sections.removeClass('active');
			$campusSections.show();

			const newBackground = $($campusSections[0]).attr('data-bg');
			$('.section-scroll-to').attr('data-background', newBackground);

			if ( $.inArray('distanciel', attendance) !== -1 ) {
				$campusSections.find('.block-heading__hidden').hide();
				$campusSections.find('.block-heading__hidden[data-type="type-4"]').show();
			} else if ( $.inArray('libourne', attendance) !== -1 && $.inArray('bordeaux', attendance) !== -1 || $.inArray('bordeaux_libourne', attendance) !== -1 ) {
				$campusSections.find('.block-heading__hidden').hide();
				$campusSections.find('.block-heading__hidden[data-type="type-3"]').show();
			} else if ( $.inArray('bordeaux', attendance) !== -1 ) {
				$campusSections.find('.block-heading__hidden').hide();
				$campusSections.find('.block-heading__hidden[data-type="type-1"]').show();
			} else if ( $.inArray('libourne', attendance) !== -1 ) {
				$campusSections.find('.block-heading__hidden').hide();
				$campusSections.find('.block-heading__hidden[data-type="type-2"]').show();
			}
		} else {
			$campusSections.hide();
		}

		if (attendance.length && eligibilityCPF.length) {
			$articles.each((index, elem) => {
				const $elem = $(elem);
				const articleAttendances = $elem.attr('data-attendance').split('_');

				// if (attendance.includes($elem.attr('data-attendance')) && eligibilityCPF.includes($elem.attr('data-eligible-cpf'))) {
				if (attendance.filter(element => articleAttendances.includes(element)).length && eligibilityCPF.includes($elem.attr('data-eligible-cpf'))) {
					$elem.parent().removeClass('hidden');
				} else {
					$elem.parent().addClass('hidden');
				}
			});
		} else if (! attendance.length && eligibilityCPF.length) {
			$articles.each((index, elem) => {
				const $elem = $(elem);

				if (eligibilityCPF.includes($elem.attr('data-eligible-cpf'))) {
					$elem.parent().removeClass('hidden');
				} else {
					$elem.parent().addClass('hidden');
				}
			});
		} else if (attendance.length && ! eligibilityCPF.length) {
			$articles.each((index, elem) => {
				const $elem = $(elem);
				const articleAttendances = $elem.attr('data-attendance').split('_');

				// if (attendance.includes($elem.attr('data-attendance'))) {
				if (attendance.filter(element => articleAttendances.includes(element)).length) {
					$elem.parent().removeClass('hidden');
				} else {
					$elem.parent().addClass('hidden');
				}
			});
		} else {
			$articles.each((index, elem) => {
				const $elem = $(elem);

				$elem.parent().removeClass('hidden');
			});
		}

		$sections.each((index, elem) => {
			const $elem = $(elem);

			if (! $elem.find('.grid .grid__col:not(.hidden)').length) {
				$elem.addClass('no-active-articles');
			} else {
				$elem.removeClass('no-active-articles');
			}
		});

		if ( ! categories.length && ! attendance.length && ! eligibilityCPF.length ) {
			$sections.addClass('active');
			$sections.show();
			$campusSections.hide();
			$campusSections.removeClass('active');

			const newBackground = $($('.js-section-filter.active')[0]).attr('data-bg');

			$('.section-scroll-to').attr('data-background', newBackground);
		}
	}
}


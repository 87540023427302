import { $body } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.js-header';
const $container = $(_element);

if ( $container.length ) {

	$container.find( '.js__nav a' ).on( 'click', function( event ) {
		const $this = $(this);
		const _href = $this.attr( 'href' );
		const $parent = $this.parent();

		if ( _href.indexOf( '#' ) >= 0 ) {
			const _hash = _href.split( '#' );
			const $id   = $( '.js__nav-large #' + _hash[1] );

			if ($parent.is('.menu-item-has-children') && ! $parent.is('.submenu-open')) {
				event.preventDefault();

				$parent.addClass('submenu-open');

				return;
			}

			$parent.removeClass('submenu-open');

			if ( $id.length ) {
				event.preventDefault();

				$body.addClass( className.ShowNavLarge );
				$id.addClass( className.Active ).siblings().removeClass( className.Active );
			}
		}
	});

	$container.find( '.js__close-nav-large' ).on( 'click', function( event ) {
		event.preventDefault();

		$body.removeClass( className.ShowNavLarge );
		$container.find( '.js__nav-large > *' ).removeClass( className.Active );
	});
}

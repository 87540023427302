import { $window } from '../utils/globals';
import className from '../utils/constant-names';

/**
 * Init Simple Slider
 *
 * @return {void}
 */
const initSimpleSlider = (_element = '.js-simple-slider') => {
    const $container = $(_element);

    if (! $container.length ) {
    	return false;
    }

    const speed = 7000;

    let _timer = null;

    $window.on( 'load', function() {
		updateSlider();
    });

	function updateSlider() {
		_timer = setTimeout(function() {
			$container.each( function() {
				const $slider      = $(this);
				const $slideActive = $slider.find( '.' + className.Active );
				const $next        = ( !$slideActive.next().length ) ? $slider.find('.js__holder > *:first-child') : $slideActive.next();

				$next
					.addClass(className.Active)
					.siblings()
					.removeClass(className.Active);

				updateSlider();
			});
		}, speed);
	}

	$container.find( '.js__prev' ).on( 'click', function( event ) {
		event.preventDefault();

		clearTimeout( _timer );

		const $this        = $(this);
		const $parent      = $this.parents( _element );
		const $slideActive = $parent.find( '.' + className.Active );
		const $next        = ( !$slideActive.prev().length ) ? $parent.find('.js__holder > *:last-child') : $slideActive.prev();

		$next
			.addClass(className.Active)
			.siblings()
			.removeClass(className.Active);

		updateSlider();
	});

	$container.find( '.js__next' ).on( 'click', function( event ) {
		event.preventDefault();

		clearTimeout( _timer );

		const $this        = $(this);
		const $parent      = $this.parents( _element );
		const $slideActive = $parent.find( '.' + className.Active );
		const $next        = ( !$slideActive.next().length ) ? $parent.find('.js__holder > *:first-child') : $slideActive.next();

		$next
			.addClass(className.Active)
			.siblings()
			.removeClass(className.Active);

		updateSlider();
	});
};

export default initSimpleSlider;

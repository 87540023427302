import { $window } from '../utils/globals';
import scrollToSection from '../utils/scroll-to-section';

$( 'a' ).click(function(event) {
	const $this = $(this);
	const _href = $this.attr('href');

	checkHasId( _href );
});

$window.on('load hashchange', function() {
	checkHasId( window.location.hash );
});

function checkHasId( _href ) {
	if (! _href) {
		return;
	}

	if ( _href.indexOf( '#' ) >= 0 ) {

		const _hash = _href.split( '#' );
		const $id   = $('[data-id="' + _hash[1] + '"]');

		if ( $id.length ) {
			event.preventDefault();

			scrollToSection( $id );

			history.pushState(null, null, '#' + _hash[1]);
		}
	}
}

document.addEventListener('DOMContentLoaded', function () {
  if (window.innerWidth > 992) {
    let copyBtns = document.querySelectorAll('.copy-paste-button');
    copyBtns.forEach(function(copyBtn) {
      copyBtn.addEventListener('click', copyFunction);
    });
  }

  function copyFunction() {
    let url = this.parentNode.querySelector('.copy-button-url').value;
    let msgSuccess = this.parentNode.querySelector('.share-copied-success');

    navigator.clipboard.writeText(url);
    msgSuccess.style.display = 'block';

    setTimeout(function() {
      msgSuccess.style.display = 'none';
    }, 1500);
  }
});


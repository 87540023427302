import { $body } from '../utils/globals';
import className from '../utils/constant-names';

const _element   = '.nav';
const $container = $(_element);

if ($container.length) {

    $container.find('a').on('click', function(event) {
				const $parent = $(this).parent('.menu-item');
				const _haveChild = $parent.find('> ul').length;

				if(window.innerWidth < 1220) {
					if (_haveChild ) {
							event.preventDefault();

							$container.find('.menu-item').not($parent).removeClass(className.Hover);

							$parent.toggleClass(className.Hover);
					}
				} else {
					if (_haveChild && !$parent.closest('.sub-menu').length) {
						event.preventDefault();

						$container.find('.menu-item').not($parent).removeClass(className.Hover);

						$parent.toggleClass(className.Hover);
					}
				}
    });

		$container.on('click', '.sub-menu *', function(event) {
			const $parentLi = $(this).closest('.sub-menu').parent('li');
			$parentLi.addClass(className.Hover);
    });

    $body.on('click', function(event) {
				const $target = $(event.target);
				const isOnTarget = !$target.parents(_element).length && !$target.hasClass(_element);

				if (isOnTarget) {
						$container.find('.menu-item').removeClass(className.Hover);
				}
    });
}
